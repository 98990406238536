<script setup lang="ts">
const { version, env } = useBuildInfo()
</script>

<template>
  <footer class="pb-6 text-center">
    <GButton btn="text-gray" target="_blank" to="https://multiplai.tech/">
      Copyright © 2023-Present | Multiplai Technologies
    </GButton>

    <em class="hidden text-nowrap text-0.65em text-primary leading-1.5em font-mono italic -mt-0.75em sm:ml-0.05rem sm:block">
      v{{ version }} <span v-if="env !== 'release'">({{ env }})</span>
    </em>
  </footer>
</template>
